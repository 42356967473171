import {navigate} from "gatsby";
import React from "react";
import {useIsMobile} from "../utils";

const Contact = () => {
    const { isMobile } = useIsMobile();
    return <div
        className="title2"
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 310,
            color: 'white',
            padding: isMobile ? '32px' : 0,
        }}
    >
        {/*多描述一些需求，讓我們可以幫助你。*/}
        {/*<div style={{height: 48}}/>*/}
        <div
            className="body1 my-btn"
            style={{
                cursor: 'pointer',
                lineHeight: '24px',
                padding: '8px 32px',
                textAlign: 'center',
                border: '1px solid',
                width: '250px',
                borderColor: 'black',
                borderRadius: 8,
                height: 42,
                margin: '8px 0',
            }}
            onClick={() => {
                window.open('https://m.me/cooperationcoffee', '_self' );
            }}
        >
            Facebook Messenger
        </div>
        <div
            className="body1 my-btn"
            style={{
                cursor: 'pointer',
                margin: '8px 0',
                lineHeight: '24px',
                padding: '8px 32px',
                textAlign: 'center',
                border: '1px solid',
                width: '250px',
                borderColor: 'black',
                borderRadius: 8,
                height: 42,
            }}
            onClick={() => {
                window.open('https://ig.me/m/cooperation.coffee', '_self' );
            }}
        >
            Instagram Direct
        </div>
    </div>;
}

export  default  Contact;
